import React, { ChangeEvent } from 'react'
import { FileUploadToStorageType } from '@omnicar/sam-types'
import { apiFileUploadRequest } from '../../../api/api'
import withStyledField, { IWithStyledFieldProps } from '../../../hoc/withStyledField/withStyledField'
import ActionButton from '../../ActionButton/ActionButton'
import './FileUploadField.css'

export interface IUploadFieldResponse {
  filename?: string
  filepath?: string
  success?: boolean
}

export interface IOnDidUploadEvent {
  eventName: string
  targetID: string
  response: any
  status: string
}

interface IProps extends IWithStyledFieldProps {
  accept?: string
  buttonLoadingText?: string
  buttonText?: string
  className?: string
  disabled?: boolean
  maxLength?: number
  name: string
  onBlur?: (e: any) => void
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onUploadComplete?: () => void
  onUploadEnd?: () => void
  onUploadFail?: () => void
  onUploadStart?: () => void
  onDidUpload?: (e: any) => void // Note: Gets called after an _upload_ attempt has been made, regardless if failed or successful.
  onDelete?: (e: any) => void
  canDelete?: boolean
  deleteLabel?: string
  size?: number
  uploadDescription?: string
  serverURL: string // Note: The route path to call on the server.
  value: string
  uploadStorage?: FileUploadToStorageType
  contextParent?: any
  previewURL?: string
  displayPrettyFileName?: boolean
}

interface IState {
  loading: boolean
}

// tslint:disable-next-line interface-name
export class FileUploadField extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    accept: '.gif, .jpg, .jpeg, .png, .doc, .docx, .pdf',
    buttonLoadingText: 'Uploading...',
    buttonText: 'Upload',
    className: '',
    size: 32,
    uploadDescription: 'Uploaded with Fragus FileUploadField',
  }

  public state = {
    loading: false,
  }

  public render() {
    const { handleFileChange, handleUploadClick } = this
    const { loading } = this.state
    const {
      accept,
      buttonLoadingText,
      buttonText,
      className,
      disabled,
      name,
      onBlur,
      onChange,
      onDelete,
      canDelete,
      deleteLabel,
      size,
      value,
      previewURL,
      displayPrettyFileName,
    } = this.props

    let displayedValue = displayPrettyFileName ? this.getPrettyFileName() : value

    return (
      <>
        <input
          className={className}
          disabled={loading || disabled}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          size={size}
          type="text"
          value={displayedValue}
        />
        <div className="FileUploadField__actions">
          <input hidden={true} accept={accept} onChange={handleFileChange} type="file" />
          <ActionButton
            className="FileUploadField__uploadbutton"
            disabled={loading || disabled}
            onClick={handleUploadClick}
            text={loading ? buttonLoadingText : buttonText}
          />
          {canDelete && value && (
            <ActionButton
              className="FileUploadField__deletebutton"
              disabled={loading || disabled}
              onClick={onDelete}
              text={deleteLabel}
            />
          )}
        </div>

        {previewURL && (
          <>
            <br />
            <span className="small">Preview:</span>
            <br />
            <img className="logo-image" src={previewURL} alt="" />
          </>
        )}
      </>
    )
  }

  private handleUploadClick = (e: any) => {
    e.currentTarget.previousElementSibling.click()
  }

  private handleUploadStart = () => {
    const { onUploadStart } = this.props

    this.setState({
      loading: true,
    })

    onUploadStart && onUploadStart()
  }

  private handleUploadEnd = () => {
    const { onUploadEnd } = this.props

    this.setState({
      loading: false,
    })

    onUploadEnd && onUploadEnd()
  }

  private updateInputField = (filename: string) => {
    this.props.contextParent && this.props.contextParent.setFieldValue(this.props.name, filename)
  }

  private getPrettyFileName = () => {
    const { value } = this.props
    return value.substring(value.indexOf('_') + 1) || value
  }

  private handleUploadComplete = (response: IUploadFieldResponse) => {
    const { onUploadComplete } = this.props
    const { filename } = response

    if (filename) {
      onUploadComplete && onUploadComplete()
    }
  }

  private handleUploadFail = () => {
    const { onUploadFail } = this.props

    onUploadFail && onUploadFail()
  }

  private handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { handleUploadComplete, handleUploadEnd, handleUploadFail, handleUploadStart } = this
    const { serverURL } = this.props
    const fileList: FileList | null = event.target.files
    const toStorage: FileUploadToStorageType = !this.props.uploadStorage ? 'General-Storage' : this.props.uploadStorage

    let status = null
    let response: any = {}

    handleUploadStart()

    if (fileList && fileList.length) {
      const file: File = fileList[0]
      const formData = new FormData()

      // Attach data.
      formData.append('file', file)
      formData.set('toStorage', toStorage)

      try {
        response = await apiFileUploadRequest(serverURL, formData)
        response = await response.json()
      } catch (error) {
        // tslint:disable-next-line:no-console
        console.error(error)
      }

      if (response) {
        if (response.filename) {
          status = 'ok'
          this.updateInputField(response.filename)
          handleUploadComplete(response)
        } else {
          status = 'upload-failed'
          handleUploadFail()
        }
      } else {
        // No Response = fail
        status = 'no-response-fail'
        handleUploadFail()
      }

      // This ensures a uploadEnd for the scope
      handleUploadEnd()
    } else {
      // No FileList = fail
      status = 'no-filelist-fail'
      handleUploadFail()
      handleUploadEnd()
    }

    const customEvent: IOnDidUploadEvent = {
      eventName: 'onDidUpload',
      targetID: this.props.name,
      response: !response ? null : response,
      status: !status ? 'fatal-error' : status,
    }

    this.props.onDidUpload && this.props.onDidUpload(customEvent)
  }
}

export default withStyledField(FileUploadField, 'FileUploadField')
