import React from 'react'
import './DownloadButton.css'

interface IProps {
  fileRef: string
  label: string
  className?: string
}

class DownloadButton extends React.Component<IProps, {}> {
  render() {
    return (
      <a className="DownloadButton" href={this.props.fileRef} target="_blank" rel="noopener noreferrer">
        <span>{this.props.label}</span>
      </a>
    )
  }
}

export default DownloadButton
