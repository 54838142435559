import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import 'whatwg-fetch'
import App from './containers/App/App'
import Routes from './routes/Routes'
import store, { storePersistor } from './utils/store'
import browserHistory from './utils/history'
import 'babel-polyfill'
import './style/main.css'
import { ToastContainer, Zoom } from 'react-toastify'

// Add React performance devtool if environment is development
// To use react-perf-devtool, read guide here: https://github.com/nitin42/react-perf-devtool
/*
  if (process.env.NODE_ENV === 'development') {
    // tslint:disable-next-line:no-var-requires
    const registerObserver = require('react-perf-devtool')
    registerObserver()
  }
*/

const index = (
  <>
    <ToastContainer
      draggable={true}
      closeButton={true}
      closeOnClick={true}
      pauseOnHover={true}
      pauseOnFocusLoss={false}
      hideProgressBar={false}
      position={'top-center'}
      autoClose={5000}
      newestOnTop
      theme="colored"
      transition={Zoom}
      style={{ width: '30vw', letterSpacing: '0.17ch' }}
    />
    <Provider store={store}>
      <PersistGate loading={null} persistor={storePersistor}>
        <Router>
          <ConnectedRouter history={browserHistory}>
            <App>
              <Routes />
            </App>
          </ConnectedRouter>
        </Router>
      </PersistGate>
    </Provider>
  </>
)

ReactDOM.render(index, document.getElementById('root') as HTMLElement)
