import { IContractProviderResponse, TIsoCountry } from '@omnicar/sam-types'
import React from 'react'
import { connect } from 'react-redux'
import { IRootState } from 'reducers/initialState'
import PPNavigation from './PPNavigation/PPNavigation'
import PPOptionDetails from './PPOptionDetails/PPOptionDetails'
import PPOptionList from './PPOptionList/PPOptionList'
import PPPrices from './PPPrices/PPPrices'
import PPProviderDetails from './PPProviderDetails/PPProviderDetails'
import PPProviderList from './PPProviderList/PPProviderList'
import PPExternalAccountCreate from './PPStripeDetails/PPExternalAccountCreate'
import PPStripeDetails from './PPStripeDetails/PPStripeDetails'
import PPTemplateDetails from './PPTemplateDetails/PPTemplateDetails'
import PPTemplateList from './PPTemplateList/PPTemplateList'
import PPUserList from './PPUserList/PPUserList'
import './ProviderPage.css'

interface IReducerProps {
  providerDetails: IContractProviderResponse
  route: string
}

// tslint:disable-next-line interface-name Because of a lint error we need to investigate
class ProviderPage extends React.Component<IReducerProps, {}> {
  public render() {
    const { route, providerDetails } = this.props

    const routeSubTitle = this.getRouteSubTitle()
    const isoCountry: TIsoCountry | 'n/a' = (providerDetails?.country?.isoName as TIsoCountry) || 'n/a'

    window.scrollTo(0, 0)

    return (
      <div className="ProviderPage">
        <div className="ProviderPage__inner">
          <header className="ProviderPage__header">
            <div className="ProviderPage__header-info">
              {route === 'provider' && (
                <>
                  <h1 className="ProviderPage__title">Providers</h1>
                  <h2 className="ProviderPage__subtitle">Provider List</h2>
                </>
              )}
              {route === 'provider-create' && (
                <>
                  <h1 className="ProviderPage__title">New Provider</h1>
                  <h2 className="ProviderPage__subtitle">Create New Contract Provider</h2>
                </>
              )}
              {route !== 'provider' && route !== 'provider-create' && (
                <>
                  <h1 className="ProviderPage__title">
                    <div className="ProviderPage__title-name">
                      {`${providerDetails && providerDetails.administrativeName} (${isoCountry})`}
                    </div>
                    <div className="ProviderPage__title-id">
                      Contract Provider #{providerDetails && providerDetails.providerIdentifier}
                    </div>
                    {providerDetails && providerDetails.state === 'Inactive' && (
                      <div className="ProviderPage__title-status-inactive">PROVIDER HAS BEEN DEACTIVATED</div>
                    )}
                  </h1>
                  <h2 className="ProviderPage__subtitle">{routeSubTitle}</h2>
                </>
              )}
            </div>
          </header>
          {route !== 'provider' && (
            <nav className="ProviderPage__navigation">
              <PPNavigation />
            </nav>
          )}
          <main className="ProviderPage__content">
            {route === 'provider' && (
              <React.Fragment>
                <div className="ProviderPage__providerlist">
                  <PPProviderList />
                </div>
              </React.Fragment>
            )}
            {route === 'provider-create' && (
              <div className={`ProviderPage__providercreate`}>
                <PPProviderDetails create={true} />
              </div>
            )}
            {route === 'provider-details' && (
              <div className={`ProviderPage__providerdetails`}>
                <PPProviderDetails />
              </div>
            )}
            {route === 'template' && (
              <div className={`ProviderPage__templatelist`}>
                <PPTemplateList />
              </div>
            )}
            {route === 'template-create' && (
              <div className={`ProviderPage__templatecreate`}>
                <PPTemplateDetails create={true} />
              </div>
            )}
            {route === 'product-template-create' && (
              <div className={`ProviderPage__templatecreate`}>
                <PPTemplateDetails create={true} product={true} />
              </div>
            )}
            {route === 'template-details' && (
              <div className={`ProviderPage__templatedetails`}>
                <PPTemplateDetails />
              </div>
            )}
            {route === 'option' && (
              <div className={`ProviderPage__optionlist`}>
                <PPOptionList />
              </div>
            )}
            {route === 'option-create' && (
              <div className={`ProviderPage__optioncreate`}>
                <PPOptionDetails create={true} />
              </div>
            )}
            {route === 'option-details' && (
              <div className={`ProviderPage__optiondetails`}>
                <PPOptionDetails />
              </div>
            )}
            {route === 'stripe' && (
              <div className={`ProviderPage__stripedetails`}>
                <PPStripeDetails />
              </div>
            )}
            {route === 'external-account-create' && (
              <div className={`ProviderPage__external_account_create`}>
                <PPExternalAccountCreate />
              </div>
            )}
            {route === 'user' && (
              <div className={`ProviderPage__userlist`}>
                <PPUserList />
              </div>
            )}
            {route === 'prices' && (
              <div className={`ProviderPage__prices`}>
                <PPPrices />
              </div>
            )}
          </main>
        </div>
      </div>
    )
  }

  private getRouteSubTitle = () => {
    let subTitle = ''
    const { route } = this.props

    switch (route) {
      case 'provider-details':
        subTitle = 'Provider Details'
        break
      case 'template':
        subTitle = 'Templates List'
        break
      case 'template-create':
        subTitle = 'Create new Template'
        break
      case 'template-details':
        subTitle = 'Template Details'
        break
      case 'option':
        subTitle = 'Template Options List'
        break
      case 'option-create':
        subTitle = 'Create new Template Option'
        break
      case 'option-details':
        subTitle = 'Template Option Details'
        break
      case 'stripe':
        subTitle = 'Stripe Details'
        break
      case 'external-account-create':
        subTitle = 'Create new External Account'
        break
      case 'user':
        subTitle = 'User List'
        break
      default:
        break
    }

    return subTitle
  }
}

const mapStateToProps = (state: IRootState) => ({
  providerDetails: state.providerPage.providerDetails,
  route: state.providerPage.route,
})

export default connect(mapStateToProps, {})(ProviderPage)
