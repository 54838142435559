import { IContractProviderResponse, ICountryResponse } from '@omnicar/sam-types'
import React, { ChangeEvent } from 'react'
import './ModelsPage.css'
import { connect, Dispatch } from 'react-redux'
import Select, { ValueType } from 'react-select'
import { resolveCountryOptionsMap } from 'utils/country'
import { dismiss, showError, showInformmationWithoutClosing, showSuccess, ToastId } from 'utils/toastify'
import ActionTypes from '../../actions/ActionTypes'
import { countryListGet } from '../../actions/providerPageActions'
import { modelExportUrl, modelUpload, syncWarrantyCatalogue } from '../../api/api'
import { IRootState } from '../../reducers/initialState'

interface IReducerProps {
  providerDetails: IContractProviderResponse
  route: string
  countryList: ICountryResponse[]
}

interface IActionProps {
  countryListGet: () => Promise<void>
}

interface IState {
  currentAction: string | null
  exportCountry: ICountryOption
}

interface ICountryOption {
  value: string
  label: string
}

interface IResponseData {
  message: string
}

const defaultCountryOption: ICountryOption = {
  value: 'DK',
  label: 'Denmark',
}

class ModelsPage extends React.Component<IReducerProps & IActionProps & IState> {
  public state: IState = {
    currentAction: null,
    exportCountry: defaultCountryOption,
  }

  public componentDidMount() {
    this.props.countryListGet()
    const activeCountryOption = resolveCountryOptionsMap(this.props.countryList)[0]
    if (activeCountryOption) return this.setState({ exportCountry: activeCountryOption })
  }

  public render() {
    const { exportCountry, currentAction } = this.state
    const { countryList } = this.props

    return (
      <div className="ModelsPage">
        <div className="ModelsPage__inner">
          <header className="ModelsPage__header">
            <div className="ModelsPage__header-info">
              <h1 className="ModelsPage__title">Models</h1>
              <h2 className="ModelsPage__subtitle">Manage models</h2>
            </div>
          </header>
          <main className="ModelsPage__content">
            <h3>Export/import models</h3>
            <div>
              {countryList?.map((c) => (
                <a
                  key={`country-link-${c.isoName}`}
                  className="ActionButton"
                  href={modelExportUrl(c.isoName)}
                  title={`Download ${c.countryName} model`}
                >
                  {`Download ${c.countryName} model`}
                </a>
              ))}
            </div>
            <div className="ModelsPage__bottom-container">
              {currentAction ? (
                <label className="ActionButton">{currentAction}, please wait...</label>
              ) : (
                <>
                  <Select
                    className="Select"
                    classNamePrefix="Select"
                    onChange={(option: ValueType<ICountryOption, any>) =>
                      this.handleCountryChange(option as ICountryOption)
                    }
                    value={exportCountry}
                    options={resolveCountryOptionsMap(this.props.countryList)}
                  />
                  <label htmlFor="model-upload" className="ActionButton">
                    {`Upload models and sync for ${exportCountry.label}`}
                    <input
                      id="model-upload"
                      hidden={true}
                      accept={'.xls,.xlsx'}
                      onChange={this.handleFileChange}
                      type="file"
                    />
                  </label>
                  <label
                    className="ActionButton"
                    onClick={this.handleUpdatePriceCatalogueClick}
                  >{`Warranty Model Sync for ${exportCountry.label}`}</label>
                </>
              )}
            </div>
          </main>
        </div>
      </div>
    )
  }

  private handleUpdatePriceCatalogueClick = async () => {
    this.setState({ currentAction: 'Synchronizing models into price catalogue' })

    const { value } = this.state.exportCountry

    try {
      const response: Response = await syncWarrantyCatalogue(value)
      const parsedResponse: IResponseData = await response.json()

      if (response.status === 200) {
        showSuccess(parsedResponse.message)
      } else {
        showError(`An error occured: ${parsedResponse && parsedResponse.message}`)
      }
    } catch (error) {
      this.setState({ currentAction: null })
      showError((error as Error).toString())
    }
    this.setState({ currentAction: null })
  }

  private handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files

    if (fileList && fileList.length === 1) {
      const { value } = this.state.exportCountry
      const formData = new FormData()
      const file: File = fileList[0]

      formData.append('modelmasterdata', file)

      this.setState({ currentAction: 'Uploading Models file' })

      try {
        let finalMessage: string = ''
        let response: Response = await modelUpload(value, formData)

        const parsedResponse: IResponseData = await response.json()

        if (response.status === 200) {
          finalMessage += 'Done Uploading and Syncing\n'
          finalMessage += '\n'
          finalMessage += '* ' + parsedResponse.message
          showInformmationWithoutClosing('First part done, still working, please wait...')
        } else {
          showError(`An error occured: ${parsedResponse && parsedResponse.message}`)
        }

        // if upload is ok then do a auto synchronizing
        if (response.status === 200) {
          response = await syncWarrantyCatalogue(value)
          const parsedResponse: IResponseData = await response.json()
          dismiss(ToastId.InformmationWithoutClosing)
          if (response.status === 200) {
            finalMessage += '* ' + parsedResponse.message + '\n'
            showSuccess(finalMessage)
          } else {
            showError(`An error occured: ${parsedResponse && parsedResponse.message}`)
          }
        }
      } catch (error) {
        showError((error as Error).toString())
      }
      this.setState({ currentAction: null })
    }
  }

  private handleCountryChange = (value: ICountryOption) => this.setState({ exportCountry: value })
}

const mapStateToProps = (state: IRootState) => ({
  countryList: state.providerPage.countryList,
})

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  countryListGet: () => dispatch(countryListGet()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModelsPage)
